<template>
  <div class="Teacher-WorkOnline">
    <div class="stu-module-header">
      <div class="stu-module-title">在线作业</div>
    </div>
    <el-collapse v-model="activeNames" class="content" v-if="queryRoot">
      <el-collapse-item
        class="items"
        v-for="(item, index) in courseData"
        :key="index"
        :name="index"
      >
        <template slot="title">
          <div class="content_header">
            <p>课程</p>
            <p>
              {{ item.courseName }}
            </p>
          </div>
        </template>
        <el-table
          :data="item.eduCourseWorkSets"
          @selection-change="handleSelectChange"
        >
          <el-table-column type="selection" align="center"></el-table-column>
          <el-table-column
            label="序号"
            type="index"
            width="60"
            align="center"
          ></el-table-column>
          <el-table-column
            v-for="(col, i) in columns"
            :key="i"
            :prop="col.prop"
            :label="col.label"
            align="center"
          ></el-table-column>
          <el-table-column
            align="center"
            label="操作"
            width="140"
            v-if="setRoot || seeRoot"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="setSemester(scope.row)"
                v-if="setRoot"
              >
                设置学期
              </el-button>
              <el-button type="text" @click="details(scope.row)" v-if="seeRoot">
                查看
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-collapse-item>
    </el-collapse>
    <no-root v-else />
    <SetSemester ref="SetSemesterRef" :items="setSemesterSelect" />
  </div>
</template>

<script>
import SetSemester from "./module/SetSemester.vue";
import { QueryAllCourseWorkSetByCourseIdByTea } from "@/libs/api/teacher/online";
export default {
  name: "teacher-workOnline",
  components: { SetSemester },
  data() {
    return {
      activeNames: [],

      courseData: [
        {
          courseName: "民事诉讼法",
          data: [],
        },
        {
          courseName: "马克思主义基本原理",
          data: [
            {
              workName: "在线作业",
              weight: 100,
            },
          ],
        },
      ],
      columns: [
        { label: "作业名称", prop: "workName" },
        { label: "权重", prop: "weight" },
      ],

      selectionSelect: [],
      setSemesterSelect: null, // 设置学期 选中数据
    };
  },
  computed: {
    queryRoot() {
      return this.$sysPerKey("eduTeacherWork:list");
    },
    setRoot() {
      return this.$sysPerKey("eduTeacherWorkTeam:set");
    },
    seeRoot() {
      return this.$sysPerKey("eduTeacherWork:seeWoek");
    },
  },
  watch: {
    queryRoot(n) {
      n && this.query();
    },
  },
  mounted() {
    this.queryRoot && this.query();
  },
  methods: {
    query() {
      QueryAllCourseWorkSetByCourseIdByTea().then((res) => {
        this.courseData = res.data;
        this.activeNames = this.courseData.map((f, i) => i);
      });
    },
    setSemester(row) {
      if (row) {
        this.setSemesterSelect = row;
        this.$store.dispatch("set_t_course", row); // 获取课程
        this.$refs["SetSemesterRef"].dialog = true;
      }
    },
    details(row) {
      if (row) {
        this.$router.push({
          path: "work/toView",
          name: "work-toView",
          params: {
            id: row.id,
          },
        });
      }
    },
    handleSelectChange(val) {
      this.selectionSelect = val;
    },
  },
};
</script>

<style lang="less" scoped>
.Teacher-WorkOnline {
  background: #fff;
  box-shadow: @shadow;
  .stu-module-header {
    border-bottom: 1px solid @borderBottomColor;
    padding: 20px;
  }
  .content {
    padding: 20px;
    .items {
      .content_header {
        display: flex;
        align-items: center;
        p:first-child {
          background: @mainColor;
          color: #fff;
          width: 60px;
          text-align: center;
          margin-right: 10px;
        }
      }

      .el-table {
        min-height: unset;
      }
    }
  }
}
</style>