<template>
  <div>
    <el-dialog
      :visible.sync="dialog"
      title="教学计划"
      custom-class="teaching_plan_dialog"
      width="60%"
    >
      <div class="group">
        <div class="message">
          添加本作业到教学计划中，若作业权重大于100，将不会添加成功！
        </div>
        <el-form :model="form" inline>
          <el-form-item label="年级">
            <el-select v-model="form.gradeId" clearable @change="init">
              <el-option
                v-for="(item, index) in gradeOptions"
                :key="index"
                :label="item.gradeName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="层次">
            <el-select v-model="form.stageId" clearable @change="init">
              <el-option
                v-for="(item, index) in stageOptions"
                :key="index"
                :label="item.stageName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="专业">
            <el-select v-model="form.profId" clearable @change="init">
              <el-option
                v-for="(item, index) in professionalOptions"
                :key="index"
                :label="item.profName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-table :data="tableData" @selection-change="selectionChange">
          <el-table-column type="selection" align="center"></el-table-column>
          <el-table-column label="序号" width="60" align="center">
            <template slot-scope="scope">
              <span>{{ scope.$index + (current - 1) * pageSize + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-for="(col, i) in columns"
            :key="i"
            :prop="col.prop"
            :label="col.label"
            :minWidth="col.width"
            align="center"
          ></el-table-column>
        </el-table>
        <el-pagination
          class="pagination"
          background
          @current-change="handleCurrentChange"
          :current-page="current"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="add">添加</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="time_dialog" title="设置时间" width="30%">
      <el-form :model="timeItems" label-width="115px" :rules="rules" ref="timeRef">
        <el-form-item label="答卷开始时间" prop="answerStartTime">
          <el-date-picker
            v-model="timeItems.answerStartTime"
            type="datetime"
            placeholder="选择日期时间"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            align="right"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="答卷结束时间" prop="answerEndTime">
          <el-date-picker
            v-model="timeItems.answerEndTime"
            type="datetime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间"
            align="right"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="批阅结束时间" prop="reviewEndTime">
          <el-date-picker
            v-model="timeItems.reviewEndTime"
            type="datetime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间"
            align="right"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="time_dialog = false">取消</el-button>
        <el-button type="primary" @click="confirm('timeRef')">添加</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  QueryAllEduTeachPlanByTea,
  AddCourseWorkSetPlanByCourseId,
} from "@/libs/api/teacher/online";
export default {
  name: "teaching-plan-dialog",
  props: ["items", "professionalOptions", "gradeOptions", "stageOptions"],
  data() {
    return {
      dialog: false,
      time_dialog: false,
      form: {
        gradeId: null,
        stageId: null,
        profId: null,
      },
      timeItems: {
        answerStartTime: null,
        answerEndTime: null,
        reviewEndTime: null,
      },
      rules: {
        answerStartTime: [
          { required: true, message: "请选择答卷开始时间", trigger: "blur" },
        ],
        answerEndTime: [
          { required: true, message: "请选择答卷结束时间", trigger: "blur" },
        ],
        reviewEndTime: [
          { required: true, message: "请选择批阅结束时间", trigger: "blur" },
        ],
      },
      columns: [
        { label: "年级", prop: "gradeName" },
        { label: "层次", prop: "stageName" },
        { label: "专业", prop: "profName" },
        { label: "学期", prop: "semester" },
        { label: "权重总和", prop: "weight" },
      ],
      tableData: [],
      total: 0,
      pageSize: 10,
      current: 1,

      selectionData: [],
    };
  },
  watch: {
    dialog(bool) {
      if (bool) {
        this.init();
      }
    },
  },
  methods: {
    add() {
      if (!this.selectionData.length)
        return this.$message.error("请至少选择一条数据");
      this.time_dialog = true;
    },
    confirm(refName) {
      this.$refs[refName].validate((valid) => {
        if (!valid) return false;
        const { id } = this.items;
        const { answerStartTime, answerEndTime, reviewEndTime } =
          this.timeItems;
        const data = {
          answerStartTime, //作答开始时间
          answerEndTime, //作答结束时间
          reviewEndTime, //批阅时间
          workId: id, //在线作业id
          ids: this.selectionData.map((m) => m.id).join(), //教学计划id字符串
        };
        AddCourseWorkSetPlanByCourseId(data).then((res) => {
          this.$message.success("添加成功");
          this.time_dialog = false;
          this.dialog = false;
          this.$emit("onSuccess");
        });
      });
    },
    init() {
      const item = this.items;
      const p = {
        ...this.form,
        courseId: item.courseId,
        workId: item.id,
        page: this.current,
        pageSize: this.pageSize,
      };
      QueryAllEduTeachPlanByTea(p).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total;
      });
    },
    selectionChange(val) {
      this.selectionData = val;
    },
    handleCurrentChange(page) {
      if (page) {
        this.current = page;
        this.init();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.teaching_plan_dialog {
  .group {
    padding: 2px;
    .message {
      color: #ff0000;
      font-size: 16px;
      margin-bottom: 16px;
    }
  }
}
</style>