<template>
  <div class="WorkOnline_SetSemester">
    <el-dialog :visible.sync="dialog" title="设置学期" width="70%">
      <el-form :model="form" inline>
        <el-form-item label="年级">
          <el-select v-model="form.gradeId" clearable @change="init">
            <el-option
              v-for="(item, index) in gradeOptions"
              :key="index"
              :label="item.gradeName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="层次">
          <el-select v-model="form.stageId" clearable @change="init">
            <el-option
              v-for="(item, index) in stageOptions"
              :key="index"
              :label="item.stageName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="专业">
          <el-select v-model="form.profId" clearable @change="init">
            <el-option
              v-for="(item, index) in professionalOptions"
              :key="index"
              :label="item.profName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-plus" type="primary" @click="add">
            添加
          </el-button>
          <el-button icon="el-icon-edit" type="primary" @click="updateDate">
            修改时间
          </el-button>
          <el-button icon="el-icon-sort" type="primary" @click="effective">
            是否有效
          </el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="tableData"
        :height="350"
        v-loading="loading"
        @selection-change="selectionChange"
      >
        <el-table-column type="selection" align="center"></el-table-column>
        <el-table-column label="序号" width="60" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (current - 1) * pageSize + 1 }} </span>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(col, i) in columns"
          :key="i"
          :prop="col.prop"
          :label="col.label"
          :minWidth="col.width"
          align="center"
          show-overflow-tooltip
          :formatter="col.formatter"
        ></el-table-column>
        <el-table-column label="操作" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button
              icon="el-icon-delete"
              type="danger"
              @click="onDelete(scope.row, scope.$index)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        background
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-dialog>

    <TeachingPlan
      ref="TeachingPlanRef"
      :items="items"
      @onSuccess="onSuccess"
      :gradeOptions="gradeOptions"
      :stageOptions="stageOptions"
      :professionalOptions="professionalOptions"
    />
    <UpdateTime
      ref="T_UpdateTimeRef"
      :ids="selectionData.map((m) => m.id).join()"
    />
  </div>
</template>

<script>
import TeachingPlan from "./TeachingPlan.vue";
import UpdateTime from "./UpdateTime.vue";
import {
  QueryEduCourseWorkSetPlan,
  UpdateEduCourseWorkSetPlan,
  DeletedEduCourseWorkSetPlan,
} from "@/libs/api/teacher/online";
import { EduGrade, EduStage, EduProfession } from "@/libs/api/teacher/select";

export default {
  name: "workOnline_setSemester",
  components: { TeachingPlan, UpdateTime },
  props: ["items"],
  data() {
    return {
      dialog: false,
      form: {
        gradeId: null,
        stageId: null,
        profId: null,
      },
      loading: false,
      gradeOptions: [],
      stageOptions: [],
      professionalOptions: [],
      columns: [
        { label: "年级", prop: "gradeName", width: 100 },
        { label: "层次", prop: "stageName", width: 100 },
        { label: "专业", prop: "profName", width: 120 },
        { label: "权重总和", prop: "weight" },
        { label: "作答开始时间", prop: "answerStartTime", width: 140 },
        { label: "作答结束时间", prop: "answerEndTime", width: 140 },
        { label: "批阅结束时间", prop: "reviewEndTime", width: 140 },
        {
          label: "是否有效",
          prop: "enabled",
          formatter: (row) => {
            if (row) {
              return row.enabled === 0 ? "否" : "是";
            }
          },
        },
      ],
      tableData: [],
      total: 0,
      pageSize: 10,
      current: 1,

      selectionData: [],
    };
  },
  computed: {
    course() {
      return this.$store.getters.getTCourse;
    },
    user() {
      return this.$store.getters.getUser;
    },
  },
  watch: {
    items(rows) {
      rows && this.init();
    },
  },
  mounted() {
    // this.init();
    this.selectInit();
  },
  methods: {
    init() {
      const p = {
        courseId: this.items.courseId,
        workId: this.items.id,
        ...this.form,
      };
      this.loading = true;
      QueryEduCourseWorkSetPlan(p)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.list;
          this.total = res.data.total;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    selectInit() {
      let data = new FormData();
      // data.append("siteId", this.user.siteId);
      EduGrade(data).then((res) => {
        this.gradeOptions = res.data;
      });
      EduStage(data).then((res) => {
        this.stageOptions = res.data;
      });
      EduProfession(data).then((res) => {
        this.professionalOptions = res.data;
      });
      // EduStage, EduProfession;
    },
    onSuccess() {
      this.init();
    },
    handleCurrentChange(page) {
      if (page) {
        this.current = page;
        this.init();
      }
    },
    selectionChange(val) {
      this.selectionData = val;
    },
    add() {
      this.$refs["TeachingPlanRef"].dialog = true;
    },
    updateDate() {
      if (this.selectionData.length === 0) {
        return this.$message.error("请选择一条数据");
      }
      this.$refs["T_UpdateTimeRef"].dialog = true;
    },
    effective() {
      if (this.selectionData.length === 0) {
        return this.$message.error("请选择一条数据");
      }
      UpdateEduCourseWorkSetPlan({
        ids: this.selectionData.map((m) => m.id).join(),
      }).then((res) => {
        this.$message.success(res.message);
      });
    },
    onDelete(row, index) {
      if (row) {
        this.$confirm("确定要删除吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            DeletedEduCourseWorkSetPlan({ id: row.id }).then((res) => {
              this.$message.success("删除成功");
              this.init();
            });
          })
          .catch(() => {});
      }
    },
  },
};
</script>

<style scoped lang="less">
.WorkOnline_SetSemester {
}
</style>